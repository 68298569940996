var Kap = Kap || {};

Kap.Cookies = function () {
	function getCookieKeys () {
		var ca = document.cookie.split(';');
		var list = [];
		for(var i=0;i < ca.length;i++) {
			var c = ca[i].trim();
			while (c.charAt(0)==' ') {
				c = c.substring(1,c.length);
			}
			list.push(c.split('=')[0]);
		}
		return list;
	}
	function getCookieValue (name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') {
				c = c.substring(1,c.length);
			}
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length,c.length);
			}
		}
		return null;
	}
	function createCookie (name,value,days,domain,path,secure) {
		var cookieTxt = name+"="+value;
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			cookieTxt = cookieTxt + "; expires="+date.toGMTString();
		}
		if (domain) {
			cookieTxt = cookieTxt + "; domain="+domain;
		}
		if (path) {
			cookieTxt = cookieTxt + "; path=" + path;
		} else {
			cookieTxt = cookieTxt + "; path=/";
		}
		if (secure) {
			cookieTxt = cookieTxt + ";secure";
		}
		document.cookie = cookieTxt;
		if (getCookieValue(name) === value) {
			return true;
		} else {
			return false;
		}
	}
	function eraseCookie (name) {
		createCookie(name,"",-1);
	}
	return {
		create:createCookie,
		erase:eraseCookie,
		getValue:getCookieValue,
		getKeys:getCookieKeys
	};
}();

Kap.InterstitialPopup = function() {

    var content,
        config = {
            cookieName: "kap_interstitial",
            dialogID: "interstitialDialog",
            defaultDialogSettings: {
                modal: true,
                autoOpen: true,
                width: 550
            }
        };

    var methods = {
        passExpr : function ( $daysPassExp ) {
            if (parseInt($daysPassExp,10) === 0) {
                config.defaultDialogSettings.open = function(event, ui) { $(".ui-dialog-titlebar-close").hide(); };
                config.defaultDialogSettings.title = "Password Expired!";
                content =
                    '<p class="caution-notify">Your password has expired! You must change it to continue.</p>' +
                    '<div class="buttons single"><a href="/login/password-expired">Change Password</a></div>';
            } else {
                config.defaultDialogSettings.title = "Password Expiring Soon!";
                content =
                    '<p class="caution-notify">Your password will expire in ' + $daysPassExp + ($daysPassExp > 1 ? ' days' : ' day') + ' would you like to change it now?</p>' +
                    '<div class="buttons">' +
                    '  <a class="continue" href="/account/changepassword">Yes</a> ' +
                    '  <a class="closeInterstitial">No</a>' +
                    '  <label class="remind-option"><input type="checkbox" name="hidePasswordReset" value="1"/>Do not remind me again.</label>' +
                    '</div>';

            }
            $(document).ready(function(){
                showInterstitial();
                $('a.closeInterstitial','#' + config.dialogID).on('click', function(event){
                    if ($('input[name=hidePasswordReset]','#' + config.dialogID).is(':checked')) {
                        $.get('/expr/password-expiring?hidePasswordReset=1');
                    }
                    $('#' + config.dialogID).dialog("close");
                });
            });
        }
    }

    function updateConfiguration ( inConfig ) {
        $.merge(config,inConfig);
    }

    function addInterstitialMethod ( name, func ) {

        if (methods[name]) {
            return false;
        } else {
            methods[name] = func;
            return true;
        }
    }

    function checkCookie () {
        var val,
            ip,
            method,
            input;

        val = decodeURIComponent(Kap.Cookies.getValue(config.cookieName));
        ip = val.split(':');
        method = ip[0];
        input = ip[1];

        if (methods[method]) {
            methods[method].call(this, input);
        }
        Kap.Cookies.erase(config.cookieName,document.location.host);
    }

    function showInterstitial () {
        $('body').append('<div id="' + config.dialogID + '"></div>');
        $('#' + config.dialogID).html(content);
        $('#' + config.dialogID).dialog(config.defaultDialogSettings);
    }

    return {
            config:updateConfiguration,
            init:checkCookie
    };
}();

Kap.InterstitialPopup.init()
